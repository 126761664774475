import {BatchTable} from '../components/batch_table/BatchTable';
import {ConfirmationModal} from '../components/confirmation_model/ConfirmationModal';
import {useMemo, useState} from 'react';
import mockBatches from '../mock/mock-batches.json';
import {LocalClearanceBatch} from '../shared/Types';
import './local-clearance-overview.css';
import {ActionContainer} from '../components/action_container/ActionContainer';


export function LocalClearanceOverview() {
    const [allDatesSelected, setAllDatesSelected] = useState<boolean>(false);
    const [selectedDates, setSelectedDates] = useState<string[]>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);


    // TODO: When integrating with GraphQL, please use the useEffect hook to fill in the missing batches
    const fillMissingDates = (batches: LocalClearanceBatch[]) => {
        const enrichedBatches: LocalClearanceBatch[] = [];
        // const currentDate = new Date()
        const currentDate = new Date(2024, 9, 31);
        const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 2);
        const endDate = new Date(currentDate);
        endDate.setDate(currentDate.getDate() - 1);

        for (let indexDate = startDate; indexDate <= endDate; indexDate.setDate(indexDate.getDate() + 1)) {
            if (batches.find(batch => batch.day_bucket_date === indexDate.toISOString().split('T')[0])) {
                enrichedBatches.push(batches.find(batch => batch.day_bucket_date === indexDate.toISOString().split('T')[0]) as LocalClearanceBatch);
            } else {
                enrichedBatches.push({
                    day_bucket_date: indexDate.toISOString().split('T')[0],
                    status: 'NEW'
                })
            }
        }
        return enrichedBatches
    }
    const data = useMemo(() => fillMissingDates(mockBatches), []);

    const handleAllSelection = () => {
        if (allDatesSelected) {
            setAllDatesSelected(false);
            setSelectedDates([]);
        } else {
            setAllDatesSelected(true);
            setSelectedDates(data.filter(batch => batch.status === 'NEW').map(batch => batch.day_bucket_date));
        }
    }

    const handleDateSelection = (date: string) => {
        const currentSelectedDates = [...selectedDates];
        if (currentSelectedDates.indexOf(date) > -1) {
            currentSelectedDates.splice(currentSelectedDates.indexOf(date), 1);
        } else {
            currentSelectedDates.push(date);
        }
        setSelectedDates(currentSelectedDates);
    }


    const handleMonthChanged = (month: number, year: number) => {
        setSelectedDates([]);
        console.log('Current selected month and year: ', month, year);
    }

    return (
        <div id="main-container">
            <ActionContainer
                handleMonthChanged={handleMonthChanged}
                selectedDates={selectedDates}
                setIsModalVisible={setIsModalVisible}
            />
            <BatchTable
                data={data}
                allDatesSelected={allDatesSelected}
                selectedDates={selectedDates}
                handleAllSelection={handleAllSelection}
                handleDateSelection={handleDateSelection}
            />
            <ConfirmationModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                selectedDates={selectedDates}
            />
        </div>
    )
}