import {StatusIndicator, Tooltip} from '@nike/eds';
import {LocalClearanceBatch} from '../../shared/Types';
import "./BatchStatusIndicator.css";

export function BatchStatusIndicator({status, updated_at, error_message}: LocalClearanceBatch) {
    const updatedAt =
        <span className="eds-type--legal-1 eds-color--secondary updated-at">
            {new Date(Date.parse(updated_at + " GMT")).toLocaleString()}
        </span>;
    switch (status) {
        case "PROCESSING":
            return <div>
                <StatusIndicator label="Release in progress" status="neutral" className="indicator-base-margin"/>
                {updatedAt}
            </div>
        case "PROCESSED":
            return <div>
                <StatusIndicator label="Released successfully" status="success" className="indicator-base-margin"/>
                {updatedAt}
            </div>
        case "ERROR":
            return <div className="error-indicator-container">
                <div className="error-indicator indicator-base-margin">
                    <StatusIndicator label="Release error" status="danger"/>
                    <Tooltip bodySlot={error_message} placement={"top"}><span className="info-icon">&#9432;</span></Tooltip>
                </div>
                {updatedAt}
            </div>
        default:
            return <div className="eds-type--subtitle-2">
                Not released
            </div>
    }
}