import {Tab, TabGroup} from '@nike/eds'
import './MonthSelection.css';
import {useState} from 'react'

type props = {
    handleMonthChanged: (month: number, year: number) => void
}

export function MonthSelection({handleMonthChanged}: props) {
    const [activeMonth, setActiveMonth] = useState("current-month");
    const currentDate = new Date();
    const getPreviousMonthDate = (date: Date) => {
        const clone = new Date(date);
        clone.setDate(0);
        return clone;
    }
    const availableMonths = [
        {
            id: "current-month",
            month: currentDate.getMonth() + 1,
            year: currentDate.getFullYear(),
        },
        {
            id: "previous-month",
            month: getPreviousMonthDate(currentDate).getMonth() + 1,
            year: getPreviousMonthDate(currentDate).getFullYear(),
        },
    ];

    const selectMonth = (id: String) => {
        const selectedMonth = availableMonths.find(am => am.id === id);
        if (selectedMonth) {
            setActiveMonth(selectedMonth.id);
            handleMonthChanged(selectedMonth.month, selectedMonth.year);
        }
    }
    return (
        <div id="month-tab-container">
            <TabGroup activeId={activeMonth} name="month-tab-selection" onChange={e => selectMonth(e.target.id)}>
                {availableMonths.map((am, index) => <Tab id={am.id} key={index}>{`${am.year} - ${am.month}`}</Tab>)}
            </TabGroup>
        </div>

    )
}