import {MonthSelection} from '../month_selection/MonthSelection';
import {Button} from '@nike/eds';
import './ActionContainer.css';

type props = {
    handleMonthChanged: (month: number, year: number) => void,
    selectedDates: String[],
    setIsModalVisible: (status: boolean) => void
}

export function ActionContainer({handleMonthChanged, selectedDates, setIsModalVisible}: props) {
    return (
        <div id="action-container">
            <MonthSelection handleMonthChanged={handleMonthChanged}/>

            <div id="action-button-container">
                <span className="eds-type--body-3">{selectedDates.length} items selected</span>
                <Button id="submit-button" onClick={() => setIsModalVisible(true)}
                        disabled={selectedDates.length === 0}>
                    Release selected batches for declaration
                </Button>
            </div>
        </div>
    )
}